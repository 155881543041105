import { CompanyProps, TabProps } from './utils'

const Companies: CompanyProps[] = [
    {
        name: 'Inagene Diagnostics Inc',
        title: 'Senior Software Engineer',
        calendar: 'June 2024 – Present',
        info: 'Rebuilt the company’s legacy site from scratch using React and Tailwind CSS for a modern, responsive, and maintainable front-end experience. Enhanced and refactored backend functionality using Laravel to support new features and improved performance. Migrated the development environment to Docker, ensuring consistency and reliability across the engineering team. Actively contributed to fixing and implementing frontend and backend features based on internal tickets. Diagnosed and resolved long-standing issues in the test suite; restored and stabilized Cypress end-to-end testing coverage.',
        logo: 'inagene.png',
        tech: [
            'vanilajs',
            'php',
            'mysql',
            'react',
            'html5',
            'css3',
            'laravel',
            'docker',
            'eslint',
            'github',
            'i18n',
            'jira',
            'npm',
            'prettier',
            'storybook',
            'tailwind',
            'webpack',
        ],
    },
    {
        name: 'StreamYard (Hopin LTD)',
        title: 'Senior Software Engineer',
        calendar: 'September 2021 – June 2024',
        info: 'Developed and integrated HTML5 video player components for the StreamYard streaming platform using React.js, optimizing for accessibility and low latency live streaming. • Led the development of a fully portable plugin for low latency live streaming, implemented across all company products.',
        logo: 'hopin.png',
        tech: [
            'vanilajs',
            'typescript',
            'react',
            'html5',
            'css3',
            'docker',
            'eslint',
            'github',
            'i18n',
            'jira',
            'npm',
            'pnpm',
            'prettier',
            'reactrouter',
            'storybook',
            'tailwind',
            'webpack',
        ],
    },
    {
        name: 'MindGeek',
        title: 'Senior Front-end Developer',
        calendar: 'November 2016 - September 2021',
        info: 'Contributed to the development and maintenance of the HTML5 player utilized across all company tube sites, focusing on core and skin development using vanilla JavaScript. • Implemented new features such as ChromeCast and adaptive streaming, and planned releases for feature implementation.',
        logo: 'mindgeek.png',
        tech: [
            'vanilajs',
            'php',
            'apache',
            'mysql',
            'html5',
            'css3',
            'jira',
            'jquery',
            'prettier',
            'eslint',
            'lazyload',
            'npm',
            'webpack',
            'gulpjs',
        ],
    },
    {
        name: 'Flosites',
        title: 'WordPress Developer',
        calendar: 'October 2011 – October 2013',
        info: 'Developed WordPress themes and managed all stages of development, including client communication and site launch. • Ensured responsive design implementation for optimal viewing across devices. • Collaborated with designers to create custom themes and plugins, enhancing site functionality and user experience.',
        logo: 'flosites.jpg',
        tech: [
            'php',
            'mysql',
            'apache',
            'html5',
            'css3',
            'jquery',
            'wordpress',
            'lazyload',
            'gitlab',
            'bitbucket',
        ],
    },
    {
        name: 'Capital Payment Solutions',
        title: 'Backend Developer',
        calendar: 'September 2007 – September 2011',
        info: 'Contributed to various projects including the development of a content management system and backend services. • Maintained and enhanced online shops, internal services, and order management systems.',
        logo: 'cps.jpeg',
        tech: ['php', 'mysql', 'apache', 'html5', 'css3', 'jquery', 'svn'],
    },
]
const Skills: TabProps[] = [
    {
        title: 'Programming Languages',
        shortTitle: 'Languages',
        slug: 'langs',
        text: 'JavaScript, Typescript, HTML5, CSS3, JSON, SASS, LESS, PHP.',
    },
    {
        title: 'Libraries & Frameworks',
        shortTitle: 'Libraries',
        slug: 'libs',
        text: 'ReactJS, Laravel, Rest API, Auth0, i18n, JQuery, Tailwind, Bootstrap, Storybook, Google Analytics, Jest, Cypress, Playwright, MySQL.',
    },
    {
        title: 'Tools & Platforms',
        shortTitle: 'Tools',
        slug: 'tools',
        text: 'Git, GitHub, GitLab, Bitbucket, JIRA, Agile, yarn, NPM, Gulp, Webpack, Visual Studio, Intellij Idea, Postman, Notepad ++, Sourcetree, Adobe Photoshop, Figma, MacOS, Windows, Linux.',
    },
]

export { Companies, Skills }
